import React from "react";
import Layout from "../components/Layout";
import { graphql } from "gatsby";
import Buttonv3 from "../components/buttons/buttonv3";
import PropTypes from "prop-types";
import HeroComponentV2 from "../components/hero/HeroComponentV2";
import { isLight } from "../store/colorDetector";

const ServicesNewPage = ({ data }) => {
  return (
    <Layout>
      <div className="home">
        <HeroComponentV2
          subHeading={"Case study"}
          flexStyle={{ alignItems: "center" }}
          title={data.strapiSitePages.caseStudy.pageTitle}
          description={data.strapiSitePages.caseStudy.description}
          footerComponent={() => (
            <Buttonv3 title={"Request Quotes  →"} toLink="/contact-us" />
          )}
          rightComponent={() => (
            <img
              style={{ margin: "0 auto" }}
              src={
                data.strapiSitePages.caseStudy.headerImage.localFile
                  .childImageSharp.fluid.src
              }
              alt=""
              width={"80%"}
            />
          )}
        />
        <Section1 data={data.strapiSitePages.caseStudy.section1} />
        {data.strapiSitePages.caseStudy.sections.map((v, k) => (
          <CasestudyPageBody key={k} kk={k} data={v} />
        ))}
      </div>
    </Layout>
  );
};

const Section1 = ({ data }) => (
  <div className="HomeAboutUs" style={{ backgroundColor: "ECF8F9" }}>
    <div
      className="section-bg"
      style={{ backgroundColor: "white" }} //dynamic
    />
    <div className={`onlyText`} style={{ marginBottom: 10 }}>
      <div style={{ fontFamily: "Poppins" }}>
        {/* <div className="ti">{"hello"}</div> */}
        <h2 className="homeAboutTitle">{data.title}</h2>
        <div
          className="page-body"
          dangerouslySetInnerHTML={{ __html: data.body }}
        />
      </div>
    </div>
  </div>
);

const CasestudyPageBody = ({ kk, data }) => (
  <div className="HomeAboutUs" style={{ backgroundColor: "ECF8F9" }}>
    <div
      className="section-bg"
      style={{ backgroundColor: data.sectionColor }}
    />
    <div
      className={`onlyText ${kk % 2 !== 0 ? "mobile-view" : ""}`}
      style={{ marginBottom: 10 }}
    >
      <div
        className="sub1"
        style={{ color: isLight(data.sectionColor) ? "black" : "white" }}
      >
        <div className="ti">{data.subTitle}</div>
        <h2 className="homeAboutTitle">{data.title}</h2>
        <div className="sub22">
          <div
            className="page-body"
            dangerouslySetInnerHTML={{ __html: data.body }}
          />
          <br />
          <br />
        </div>
      </div>

      <div
        className="sub2"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={data.image.localFile.childImageSharp.fluid.src}
          alt=""
          width={"80%"}
        />
      </div>
    </div>
  </div>
);

ServicesNewPage.propTypes = {
  data: PropTypes.object,
};

export const query = graphql`
  {
    strapiSitePages {
      caseStudy {
        pageTitle
        description
        section1 {
          title
          body
        }
        sections {
          body
          title
          sectionColor
          image {
            localFile {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
          }
        }
        headerImage {
          localFile {
            childImageSharp {
              fluid {
                src
              }
            }
          }
        }
      }
    }
  }
`;

export default ServicesNewPage;
